<template>
  <div class="overall-page">
    <div class="head">
      <div class="head-left">
        <img src="@/assets/img/wang/back.png" @click="back" />
        <span>详情</span>
      </div>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <div class="info-card" v-loading="ifLoading">
        <div class="title">
          <div class="text" v-html="myInfo.applyName ? myInfo.applyName : '未抓取'"></div>
          <div class="button" @click="deleteFnMy(myInfo)">删除平台</div>
        </div>
        <div class="operation">
          <div class="operation-left">
            <div class="status">
              正常
            </div>
          </div>
          <div class="operation-right">
          </div>
        </div>
        <div class="info-detail">
          <div class="info-detail-left">
            <div class="platform">
              <div class="company">平台类型：APP</div>
              <div class="company">
                应用类别：{{ myInfo.applicationCategory ? myInfo.applicationCategory : "无" }}
              </div>
              <div class="company">
                所属区域：{{ myInfo.areaName ? myInfo.areaName : "无" }}
              </div>
              <div class="company">
                下载量：{{ myInfo.downloadNum ? myInfo.downloadNum : "无" }}
              </div>

              <div class="company">最后更新时间：{{ myInfo.latestTime|| '无' }}</div>
              <!-- <div class="content-info-middle">
                <span style="float:left;min-width:4.75rem">{{myInfo.platformType == 1 ? '网站域名' :"账号域名"}}: &nbsp; &nbsp;</span>
                <a class="aColor cursor texthidden" style="display:block;float:left" @click="$main.lookYuanWen(myInfo.homeUrl)" target="_blank"
                   rel="noopener noreferrer">{{ myInfo.homeUrl }}</a>
                <img class="cursor" style="margin-left: 0.625rem;float:left" @click="$main.copyFun(myInfo.homeUrl, myInfo.platformType == 1 ? '网站域名' :'账号域名')"
                     src="@/assets/img/wang/copy-all.png" />
              </div> -->
            </div>
          </div>
          <!-- headSmallUrl -->
          <!-- <el-image v-if="myInfo.headImage" class="img" :src="myInfo.headImage" :preview-src-list="[myInfo.headImage]">
            <div slot="error" class="image-slot"> -->
          <img src="@/assets/img/zhanwei2.png" alt="" class="img" />
          <!-- </div> -->
          <!-- </el-image> -->
          <!-- <img v-else src="../../assets/img/zhanwei.png" alt="" srcset=""> -->
        </div>
      </div>
      <div class="info-type">
        <div class="cut">
          <div class="cut-left">
            <div class="edit" @click="editFn(myInfo)">编辑</div>
          </div>
          <div class="cut-right">

          </div>
        </div>
        <div class="p-20">
          <!--公共账号信息 -->
          <div class="webDetailsInInput">
            <div class="webDetailsInInputP">
              <p>
                <img src="@/assets/img/listImg1.svg" alt="" class="listImg-detils">
                <span>APP信息</span>
              </p>
            </div>
            <div class="account-information">
              <div class="website-name">应用名称：{{myInfo.applyName ||  '无'}}</div>
              <div class="website-name">开发者名称：{{myInfo.developerName||'无'}}</div>
              <div class="website-name">法人代表： {{ myInfo.legalRepresentative || "无" }}</div>
              <div class="website-name">联系信息： {{ myInfo.phone || '无' }}</div>
              <div class="website-name">最后更新时间： {{ myInfo.latestTime || "无" }}</div>
              <div class="website-name">注册地址： {{ myInfo.registeredAddress || "无" }}</div>
              <div class="website-name">运营者名称： {{ myInfo.operator || "无" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.vue";
import {
  appDel,
} from "@/api/appAccount/index";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      myInfo: {},
      routerList: ["APP台账", "APP详情"],
      ifLoading: false,
      list: []
    }
  },
  mounted() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    this.myInfo = params
  },
  methods: {
    back() { 
      this.$router.push('/appAccount')
    },
    editFn(item) {
      this.$main.routeResolve('/appEdit', item)
    },
    deleteFnMy(item) {
      console.log(item);
      this.$confirm('此操作将永久删除该App, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await appDel(item.id)
        if (res.data.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.$router.push('/appAccount')
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    deleteaccount() {

    }
  }
}
</script>

<style lang="scss" scoped>
.overall-page {
  width: 100%;
  .head {
    display: flex;
    width: 100%;
    height: 56px;
    background: #ffffff;
    position: fixed;
    margin-top: 0;
    z-index: 100;
    .head-left {
      display: flex;
      height: 100%;
      width: 50%;
      align-items: center;
      img {
        height: 38px;
        width: 38px;
        margin-left: 23px;
        margin-right: 15px;
        cursor: pointer;
      }
      span {
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
  }
  .page {
    padding-top: 72px;
    .info-card {
      display: flex;
      flex-direction: column;
      height: auto;
      width: 100%;
      background: #ffffff;
      padding: 32px 40px;
      margin-top: 16px;
      .title {
        display: flex;
        height: 32px;
        width: 100%;
        margin-bottom: 24px;
        .text {
          height: 32px;
          width: calc(100% - 88px);
          line-height: 32px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #333333;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 88px;
          height: 32px;
          background: #fff2f3;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #e60b1e;
          cursor: pointer;
        }
      }
      .operation {
        display: flex;
        height: 32px;
        display: flex;
        justify-content: space-between;
        .operation-left {
          display: flex;
          align-items: center;
          width: 40%;
          height: 32px;
          .status {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            height: 32px;
            padding: 0 20px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            background: #02bc7c;
            margin-right: 24px;
          }
          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-right: 8px;
          }
          .select {
            width: 110px;
            height: 32px;
            margin: 0px 24px 0 8px;
            ::v-deep .el-input__inner {
              width: 110px;
              height: 32px;
              border-radius: 4px;
            }
            ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            ::v-deep .el-input__icon.el-icon-arrow-down {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            ::v-deep .el-input__icon.el-icon-circle-close {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            ::v-deep .el-cascader {
              height: 32px !important;
              margin-top: 0px;
              .el-input.el-input--suffix {
                height: 32px;
                display: flex;
                align-items: center;
              }
            }
          }
        }
        .operation-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 50%;
          height: 32px;
          .bad {
            display: flex;
            align-items: center;
            height: 16px;
            width: auto;
            cursor: pointer;
            margin-right: 24px;
            img {
              height: 16px;
              width: 16px;
              margin-right: 5px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e60b1e;
            }
          }
          .contet {
            display: flex;
            align-items: center;
            height: 16px;
            width: auto;
            cursor: pointer;
            margin-right: 24px;
            img {
              height: 14px;
              width: 13px;
              margin-right: 5px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
          .management {
            display: flex;
            align-items: center;
            height: 16px;
            width: auto;
            cursor: pointer;
            img {
              height: 14px;
              width: 14px;
              margin-right: 5px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }
      }
      .info-detail {
        display: flex;
        align-items: center;
        height: 188px;
        width: 100%;
        background: #f4f5fa;
        border-radius: 4px;
        margin-top: 16px;
        .info-detail-left {
          display: flex;
          flex-direction: column;
          height: 100%;
          padding: 32px;
          width: calc(100% - 190px);
          .platform {
            display: flex;
            flex-wrap: wrap;
            height: 20px;
            width: 100%;

            // .platform-type {
            //   height: 20px;
            //   width: 353px;
            //   line-height: 20px;
            //   font-size: 14px;
            //   font-family: PingFangSC-Regular, PingFang SC;
            //   font-weight: 400;
            //   color: #333333;
            // }

            .company {
              height: 20px;
              // width: calc(100% - 353px);
              width: 33%;
              line-height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-bottom: 32px;
            }
          }
        }
        .img {
          width: 110px;
          height: 110px;
          margin: 0 40px;
        }
      }
    }
    .info-type {
      width: 100%;
      height: auto;
      background: #ffffff;
      border-radius: 4px;
      margin-top: 16px;
      .cut {
        display: flex;
        align-items: center;
        height: 54px;
        padding: 0 40px;
        border-bottom: 1px solid #cccccc;
        .cut-left {
          display: flex;
          width: 50%;
          height: 100%;
          align-items: center;
          .edit {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 36px;
            background: #f58030;
            border-radius: 4px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            cursor: pointer;
          }
          .tab {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            height: 100%;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-right: 64px;
            cursor: pointer;
          }
        }
        .cut-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 50%;
          height: 100%;
          .edit {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 36px;
            background: #f58030;
            border-radius: 4px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
      .account-information {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        padding: 60px 80px;
        // .list-box {
        //   display: flex;
        //   height: 20px;
        //   width: 100%;
        //   margin-bottom: 24px;
        .website-name {
          width: 45%;
          display: flex;
          align-items: center;
          height: auto;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 24px;
        }
        // }
      }
    }
    .webDetailsInInput {
      border: 1px solid #e6e8f1;
      margin-bottom: 16px;
      border-radius: 4px;
      .el-row {
        padding: 15px 24px 15px 24px;
      }
      .webDetailsInInputP {
        height: 54px;
        background-color: #f5f5fc;
        border-radius: 4px 4px 0px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 24px;
        p {
          display: flex;
          align-items: center;
        }
        span {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
    .be-similar {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 24px 40px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 16px;
      .be-similar-account {
        display: flex;
        align-items: center;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
        span:nth-child(1) {
          z-index: 10;
        }
        span:nth-child(2) {
          font-size: 14px;
        }
        .be-similar-color {
          width: 77px;
          height: 6px;
          background: linear-gradient(90deg, #f89d55 0%, #f4640c 100%);
          position: absolute;
          margin-left: 0px;
          margin-top: 22px;
          z-index: 9;
        }
      }
      .data-box {
        display: flex;
        width: 100%;
        height: 137px;
        padding: 24px 0px;
        border-bottom: 1px solid #e4e6ec;
        .data-box-left {
          width: 104px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            height: 56px;
            width: 56px;
            border-radius: 50%;
          }
          .platform-type {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            width: auto;
            background: #f58030;
            max-width: 104px;
            padding: 0px 22px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-top: 9px;
          }
        }
        .data-box-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: calc(100% - 104px);
          height: 100%;
          .name-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 25px;
            margin-bottom: 16px;
            .name {
              height: 25px;
              width: 80%;
              line-height: 25px;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              cursor: pointer;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            .bad-num {
              display: flex;
              justify-content: flex-end;
              font-size: 14px;
              width: 20%;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e60b1e;
            }
          }
          .data-info {
            display: flex;
            height: 20px;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            .status {
              color: #02bc7c;
              margin-right: 46px;
            }
            .region {
              max-width: 180px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 46px;
            }
            .subject-type {
              margin-right: 46px;
            }
            .company {
              max-width: 300px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 46px;
            }
            .fnas {
              margin-right: 46px;
            }
          }
        }
      }
    }
  }
}
.popover {
  // width: 300px;
  // height: 300px;
  // background: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // justify-content:space-evenly
  .popover-type {
    cursor: pointer;
    width: 114px;
    min-height: 28px;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    padding: 4px 10px;
    background: #ff4b1f;
    border-radius: 1px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.type {
  cursor: pointer;
  width: 114px;
  min-height: 28px;
  text-align: center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  padding: 4px 10px;
  background: #ff4b1f;
  border-radius: 1px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
<style>
/* //最外层div,修改背景色，边框 */
.el-popover.monitor-yt-popover {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 0 16px 0;
  /* border-color: #146ebd; */
}
/* //修改title颜色 */
.monitor-yt-popover .el-popover__title {
  color: white;
}
/* //修改下面的小三角，属性名根据组件的placement位置做相应修改 */
.monitor-yt-popover .popper__arrow::after {
  border-right-color: rgba(0, 0, 0, 0.6) !important;
}
</style>